import { TDataGrid, useTGridApiRef, TypedGridApiRef, TypedEditDataGridProps, TypedGridColumnDefs } from '@imas/data-grid';
import { vApplicantPayRateTemplate } from "@imas/api/mangager/types";
import { openPopup } from "@imas/utils/misc";
import { useParams } from 'react-router';
import { Alignment, Spacer, XSkeleton } from '@imas/components/layout';
import { AppBar, Box, Divider, Paper, Skeleton, Typography, CircularProgress, Dialog, DialogContent, DialogActions, Button,TextField } from '@mui/material';
import { useApi, useLoadApi } from "@imas/api";
import { GetvApplicantPayRateTemplateDetail, UpdateWageMatrix } from "@imas/api/mangager/applicant";
import { USD_COL_V6 } from "@imas/data-grid";
import { makeStyles } from '@mui/styles';
import { GetApplicant, GetvApplicant, UpdateApplicant, CreateApplicant, UploadResume, ApplicantSign, GetPayRateTemplateDetail } from "@imas/api/mangager/applicant";
import moment from 'moment';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { FileViewer } from '@imas/utils/files';
import { FileTables } from '@imas/api/files';
import { GetUserSignatureByID } from '@imas/api/user';
import { useFileAsObjectURL, downloadFile } from  '@imas/utils/files';
import SignatureCanvas from 'react-signature-canvas';
import { resizeImage } from '@imas/utils/files';
import { Draw } from 'mdi-material-ui';
import { Delete } from '@mui/icons-material';
import { useAutomaticSnackbar } from '@imas/utils/snackbar';
import { SignatureDisplay } from './SignatureDisplay';
import { useNavigate } from 'react-router';
import { useDeviceInfo } from "@imas/styles";


const useStyles = makeStyles({
    focus: {
      color: '#000000!important',
      backgroundColor: '#FFFF00!important',
      '& .MuiCheckbox-root': {
        color: '#bdbd00', // Unchecked color
      },
      '& .Mui-checked': {
        color: '#bdbd00', // Checked color
      },
    },
    otherwise: {
      color: '#000000', // Set header text to black

    },
    help: {
      "& .MuiDataGrid-columnHeaderTitle": {
        color: "#000000 !important", // Force header text to be black
      },
    },
    
  });


export type APPLICANT_WAGE_MATRIX_API_REF = TypedGridApiRef<vApplicantPayRateTemplate, vApplicantPayRateTemplate, never, never>
export type APPLICANT_OFFER_GRID_COLUMNS = ["payratetemplatedetaildescription",  
    "payratetemplatedetailtrainhourdescr", "payratetemplatedetailmethodhourdescr", "payratetemplatedetailexphourdescr", "payratetemplatedetailamtmax", "apppayratedetailamt"];
export const openApplicantLandingPage = (id: string): Window | null => {
    return openPopup(`/sign-offer/${id}`, {name: 'Applicant Offer Signature Page', height: 850, width: 1500});
};

export const ApplicantLandingPage = () => {
    document.title = "IMAS";
    const { id } = useParams();
    const classes = useStyles();
	const showSnackbar = useAutomaticSnackbar();


    function encodeId(id: number): string {
        const stringId = id.toString();
        return Buffer.from(stringId).toString('base64');
      }
      const deviceInfo = useDeviceInfo();

    function decodeId(encodedId: string): number {
        const decodedString = Buffer.from(encodedId, 'base64').toString('utf8');
        return parseInt(decodedString, 10);
      }
    const applicantId = useMemo(() => {
        if(!id) return -1;
        return decodeId(id);
    }, [id]);

    const navigate = useNavigate();
    
      
    const {data: payrateTypes} = useLoadApi(GetPayRateTemplateDetail, [], []);

    

    const applicantSign = useApi(ApplicantSign);

	const apiRef: APPLICANT_WAGE_MATRIX_API_REF = useTGridApiRef();
    const { data: applicant, call: refreshApplicant} = useLoadApi(GetApplicant, [applicantId], [applicantId], {disabled: applicantId === -1});
    const { data: vapplicant, call: refreshvApplicant} = useLoadApi(GetvApplicant, [applicantId], [applicantId], {disabled: applicantId === -1});

    const { data: payrate, call: refreshedPayrate} = useLoadApi(GetvApplicantPayRateTemplateDetail, [applicantId], [applicantId], {disabled: applicantId === -1});
    const [wage, setWage] = useState<number>(0);
	const [signatureDataUrl, setSignatureDataUrl] = useState<string | null>(null);
	const [signatureOpen, setSignatureOpen] = useState(false);
    const [signedName, setSignedName] = useState<string>('');
    const handleNameChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setSignedName(
		  e.target.value
			.toLowerCase()
			.split(" ")
			.map(word => {
			  return word.charAt(0).toUpperCase() + word.slice(1);
			})
			.join(" ")
		);
	  };
	let sigCanvas: SignatureCanvas | null;
    const handleSignatureClose = () => {
		setSignatureOpen(false);
	};

    useEffect(() => {
        if(vapplicant?.offersignaturedatetime !== null &&  vapplicant?.offersignaturedatetime !== undefined)
        {
          if(applicant?.offersentdate && moment().diff(moment(applicant.offersentdate), 'month') < 1)
          {
            navigate('/offer-accepted');
          }
        }

    },[vapplicant, applicant]);


	const handleSignatureClear = () => {
		if (sigCanvas !== null) {
			sigCanvas.clear();
		}
	};
    const handleSignatureConfirm = () => {
		if (sigCanvas !== null) {

            setSignatureDataUrl(sigCanvas.toDataURL());

		}
		handleSignatureClose();
	};
  
    const fullName = useMemo(() => {
        return `${applicant?.firstname} ${applicant?.lastname}`;
    }, [applicant]);

    const totalMaxtrixSum = payrate?.reduce((total, item) => {
        return total + (item.apppayratedetailamt || 0);
      }, 0);
      useEffect(() => {

        let total = (totalMaxtrixSum ?? 0) + (applicant?.payratebaseamt ?? 0);

        setWage(total); 

    },[totalMaxtrixSum, applicant]);

    const pathLocator = useMemo(() => {
        return vapplicant?.offerSentBySignature_path_locator ?? null;
    }, [vapplicant?.offerSentBySignature_path_locator]);

    const columns = useMemo((): TypedGridColumnDefs<vApplicantPayRateTemplate,vApplicantPayRateTemplate, never, APPLICANT_OFFER_GRID_COLUMNS, 'row'> => {
        return [
            {field: 'payratetemplatedetaildescription', headerName: 'Method / Description', flex: 1, editable: false, headerClassName: classes.help},
            {field: 'payratetemplatedetailtrainhourdescr', headerName: 'Training Hrs', width: 100, editable: false, headerClassName: classes.help},
            {field: 'payratetemplatedetailmethodhourdescr', headerName: 'Method Hrs', width: 100, editable: false, headerClassName: classes.help},
            {field: 'payratetemplatedetailexphourdescr', headerName: 'Total Hrs', width: 100, editable: false, headerClassName: classes.help},
            {field: 'payratetemplatedetailamtmax', headerName: 'Factor', ...USD_COL_V6, width: 70, editable: false, headerAlign: 'right', align: 'right', headerClassName: classes.help},
            {field: 'apppayratedetailamt', headerName: 'WMA', width: 100, editable: false, align: 'right', headerAlign: 'right', headerClassName: classes.help }

        ];
    }, []);
    const onSubmit = () => {

		//submitting snackbar
		const close = showSnackbar(`Accepting Offer, this could take up to 15 seconds...`, { variant: "info", persist: true });

		let dataUrl = 'error';

		//process for typed signature
		if (signatureDataUrl === null) {
			if (sigCanvas !== null) {
				let test = sigCanvas.getCanvas();

				let ctx = test.getContext("2d");
	
				if (ctx !== null) {
	
					if (signedName.length < 25) {
						ctx.font = "100px brush script mt";
					}
					else ctx.font = "75px brush script mt";
	
					// approximate the font height
					let approxFontHeight=parseInt(ctx.font);
					let centerX = 450;
					let centerY = 225;
	
					// alter the context to center-align the text
					ctx.textAlign="center";
			
					// draw the text centered at [centerX,centerY]
					ctx.fillText(signedName, centerX, centerY+approxFontHeight/4);

					dataUrl = test.toDataURL('image/png');
				}
			}
			else {
				console.log('canvas is null');
			}
		}
		//process for drawn signature
		else {
			dataUrl = signatureDataUrl;
		}

		//convert the dataurl into a file
		let arr = dataUrl.split(','),
		bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
		while(n--){
			   u8arr[n] = bstr.charCodeAt(n);
		   }			

		   let sigfile = new File([u8arr], 'Signature for ' + applicant?.lastname);

		resizeImage(sigfile)
			.then((resizedsig) => {
                applicantSign(applicant?.id ?? -1, resizedsig).then(() => {
                    close();
                    navigate('/offer-accepted');

                    
                });
			   //updateSignature(serviceOrder!.uniqueId,  resizedsig, signatureRequest.name, toEmail, signatureRequest.email).then((response) => {
			   //	close();
			   //	showSnackbar(`Client signature submitted.`, { variant: 'success' }, true);
			   //});
			   
			})
			.catch((e) => {close();});
	};


    if (applicant?.offersentdate && moment().diff(moment(applicant.offersentdate), 'days') >= 7) {
        return (
            <>
            <AppBar position={"static"} color={"primary"} sx={{height: '56px'}}>
					<Alignment row sx={{margin: 'auto 0 auto 0'}}>

						{/*  PNL Image */}	
						<Box component={"img"} sx={{margin: '0 10px 0 10px', maxWidth: '75px', width: '-webkit-fill-available' }} src={"/images/Logo-Transparent.png"}/>
						<Typography variant={'h5'} sx={{margin: 'auto 0 auto 10px'}}>{'Offer of Employment Expired'}</Typography>

					</Alignment>
					
				</AppBar>

            </>
        );
    }
    

    return (
        <>
        <Box sx={{ backgroundColor: '#FFFFFF', minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
            <AppBar position={"static"} color={"primary"} sx={{height: '56px'}}>
					<Alignment row sx={{margin: 'auto 0 auto 0'}}>

						{/*  PNL Image */}	
						<Box component={"img"} sx={{margin: '0 10px 0 10px', maxWidth: '75px', width: '-webkit-fill-available' }} src={"/images/Logo-Transparent.png"}/>
						<Typography variant={'h5'} sx={{margin: 'auto 0 auto 10px'}}>{'Offer of Employment'}</Typography>

					</Alignment>
					
				</AppBar>

                <Box sx={{ flex: 1, overflowY: 'auto' }}> {/* Wrapping container to handle scrolling */}

            {applicant === undefined || payrate === undefined ? <CircularProgress
      sx={{ marginLeft: '10px', alignSelf: 'center'}}/> :
      <>
      <Alignment sx={{marginLeft :'30px', marginTop: '20px', marginRight: '30px'}}>

      <Alignment column>
        <Alignment row flex sx={{justifyContent: 'space-between'}}>
        <Typography>{fullName}{applicant.suffix ? ` ${applicant.suffix}` : null}</Typography>
        <Typography>{applicant.offersentdate ? moment(applicant.offersentdate).format("M/D/YYYY") : moment(moment.now()).format("M/D/YYYY")}</Typography>


        </Alignment>
        <Typography>{applicant.city} {applicant.state} {applicant.zip}</Typography>
        <Typography>{applicant.email}</Typography>
        <Typography>{applicant.phone}</Typography>
        
        <Typography fontWeight={'bold'} sx={{marginTop:'15px'}}>RE: Offer of Employment</Typography>
        <Typography sx={{marginTop:'15px'}}>Dear {applicant.firstname} {applicant.lastname}{applicant.suffix ? ` ${applicant.suffix}` : null}:</Typography>
        <Typography sx={{marginTop:'15px'}}>{vapplicant?.offerdescription_adj}</Typography>
        <Typography
  textAlign="center"
  sx={{ marginTop: '15px' }}
  dangerouslySetInnerHTML={{
    __html: (applicant.offerbenefits ?? "").replace(/\n/g, '<br />'),
  }}
/>
        <Typography sx={{marginTop:'15px'}}>{applicant.offerfooter}</Typography>
        <Typography sx={{marginTop:'15px'}}>Please sign and accept as soon as possible.</Typography>
        <Alignment row flex sx={{justifyContent: 'space-between'}}>
            <Alignment column >
                <Typography sx={{marginTop:'15px'}}>Sincerely,</Typography>
                <Typography fontWeight={'bold'}>PHOENIX NATIONAL LABORATORIES, INC.</Typography>
                {pathLocator ? (
                <Box sx={{ width: 250, height: 50 }}> {/* Set desired width and height */}
                    <SignatureDisplay offersignatureImage={vapplicant?.offerSentBySignatureJsonFile} />
                </Box>
            ) : null}

                <Typography >{vapplicant?.offerSentByName}</Typography>
                <Typography sx={{marginTop:'5px'}}> {vapplicant?.offerSentByTitle}</Typography>
                <Typography sx={{marginTop:'5px'}}> {vapplicant?.offerSentByEmail}</Typography>

                </Alignment>
                <Alignment row flex>
                <Alignment column sx={{margin: '0 20px auto auto'}}>
						<Button
							color={'primary'}
							sx={{padding: '6px', minWidth: '40px'}} 
							onClick={() => setSignatureOpen(true)}
						><Draw/></Button>

						{signatureDataUrl !== null ? 
							<Button
								color={'secondary'}
								sx={{marginTop: '10px', padding: '6px', minWidth: '40px'}} 
								onClick={() => setSignatureDataUrl(null)}
							><Delete/></Button>
						: null}
					</Alignment>
                <Alignment column>
                <Typography>Sign: </Typography>
                {signatureDataUrl !== null ? 
							<Box component={'img'} src={signatureDataUrl} sx={{width:'180px', height: '90px', backgroundColor: 'white'}} />
						: 
							<TextField 
								helperText={'Please sign your first and last name above.'}
								required
								spellCheck={false}
								size={'medium'}
								onChange={handleNameChange}
								value={signedName}
                 sx={{
                     backgroundColor: 'white', 
                     color: 'black',
                     '& .MuiOutlinedInput-root': {
                       '& fieldset': {
                         borderColor: 'black',  // Outline color when unfocused
                       },
                       '&:hover fieldset': {
                         borderColor: 'black',  // Outline color when hovered
                       },
                       '&.Mui-focused fieldset': {
                         borderColor: 'black',  // Outline color when focused
                       },
                     },
                     '& .MuiFormHelperText-root': {
                       color: 'black',  // Helper text color
                     },
                   }}                                
								InputProps={{style: {fontFamily: 'brush script mt', fontSize: 40, color: 'black' // Text color
                                }}}
							/>
						}
                        <Button disabled={signatureDataUrl === null && signedName !== fullName} 
                        onClick={() => onSubmit()}
                        sx={{
                            '&:disabled': {
                              color: 'gray', // Text color when disabled
                              backgroundColor: '#f0f0f0', // Background color when disabled
                            },
                          }}
                        >Accept</Button>
            </Alignment>
            </Alignment>
            <Alignment>
            </Alignment>
        </Alignment>

   {applicant.payratetemplateID && applicant.showpayratetemplate ? <Box display="flex" justifyContent="center">
  <Typography fontWeight={'bold'} variant={'h6'}>{"Wage Matrix"}</Typography>
</Box> : null}     
        
        </Alignment>
      </Alignment>

{payrate.length !== 0 && applicant.showpayratetemplate ?<TDataGrid<vApplicantPayRateTemplate,vApplicantPayRateTemplate, never, APPLICANT_OFFER_GRID_COLUMNS, "row">
    apiRef={apiRef}
    editMode={'row'}
    rows={payrate ?? []}
    columns={columns}
    disableColumnMenu
    
    disableColumnReorder
    disableColumnResize
    disableChildrenSorting
    sx={{marginLeft: '30px', marginRight: '30px', height: 'auto', marginTop: '10px',  "& .MuiDataGrid-columnHeaderTitle": {
      color: "#000000", // Set header text to black
    },}}
    disableMultipleRowSelection
    getRowHeight={() => 'auto'}
    
    getCellClassName={(params) => {
    if(params.row.apppayratedetailfocus === true) return classes.focus;
            return classes.otherwise;
        }}
    /> : null}
    {applicant.payratetemplateID && applicant.showpayratetemplate ?  <Alignment column sx={{marginLeft :'30px', marginRight: '30px'}}>
{payrate.length !== 0&& applicant.showpayratetemplate  ? <Alignment rowReverse sx={{marginTop: payrate.length !== 0 ? (!deviceInfo.isMd ? '3200px' : '1790px'): '50px'}}>
    <Typography marginRight={'50px'} fontWeight={'bold'}>$ {totalMaxtrixSum?.toFixed(2)}</Typography>
    <Typography marginRight={'50px'} fontWeight={'bold'}>Total:  </Typography>

    </Alignment> : null}
    
    <Typography>{`Education Base rate is determined by lifetime education and training. Wage is based on the combination of Education Base Rate (EBR) and completed Wage Matrix Additions (WMA). Wage matrix direction and progress to be directed by employee's manager during coaching sessions. Highlighted lines indicate current direction. Maximum wage rate applies based on PNL fees that are able to be billed.`}</Typography>
    
    <Typography sx={{marginTop: '30px'}}>{`Technicians shall understand that once certified in a method that method must be maintained in order to keep the rate upgrade. This is accomplished based on the requirements of the certification and may require continuing educations credits (CEU's) taking examinations(s), and performing a specified amount of work in the method certified.`}</Typography>
    <Alignment rowReverse>

    <Alignment column sx={{ marginTop: '30px'}} >
        <Alignment row sx={{marginLeft: '15px'}}>                        
            <Typography color={'black'} fontWeight={'bold'} sx={{marginTop: '5px'}}>{`Education Base Rate (EBR):`}</Typography>  
           <Typography color={'black'}fontWeight={'bold'}  sx={{marginTop: '5px', marginLeft: '63px'}}>${applicant.payratebaseamt?.toFixed(2)}</Typography>

            
   
        </Alignment>
        <Alignment row sx={{marginLeft: '15px'}}>                        
            <Typography color={'black'} fontWeight={'bold'}>{`Wage Matrix Additions (WMA):`}</Typography>
            <Typography color={'black'} fontWeight={'bold'} sx={{marginLeft: '47px'}}> ${totalMaxtrixSum?.toFixed(2)}</Typography>
        </Alignment>
        <Alignment row>
        <Divider  orientation={'horizontal'} color={'black'} sx={{marginRight: '5px', marginTop: '5px', width: '250px'}}/>
        <Divider  orientation={'horizontal'} color={'black'}  sx={{marginRight: '5px', marginTop: '5px', width: '60px', marginLeft: '20px'}}/>

        </Alignment>
        <Alignment row>                        
            <Typography color={'black'} fontWeight={'bold'} sx={{marginLeft: '15px'}}>Wage:</Typography>
            <Typography color={'black'} fontWeight={'bold'} sx={{marginLeft: '220px'}}>${wage.toFixed(2)}</Typography>    

        </Alignment>

    </Alignment>
    <Typography color={'black'} fontWeight={'bold'} sx={{marginTop: '35px'}}>{applicant.payratetemplatebasename}</Typography>                  

    </Alignment>

    <Typography sx={{marginTop: '30px'}}>{`The typical senior level NDT technician, in addition to the above requirement, will have 5+ years experience as well as being an AWS Certified Welding Inspector. The senior inspector/technician will be capable of managing the NDT technicians as well as performing certian project management functions.`}</Typography>

    </Alignment>: null}

   

    </>
}

</Box>

        </Box>
        <Dialog maxWidth={'lg'} fullWidth={true} open={signatureOpen} onClose={handleSignatureClose}>
            <Alignment sx={{backgroundColor: 'white'}}>

				<DialogContent sx={{justifyContent:'center', alignSelf:'center'}}>
					<Box sx={{justifyContent:'center', alignSelf:'center', border:2, borderColor: 'black'}}>
					<SignatureCanvas
					 	canvasProps={{width:1145, height:450}}
					 	ref={(ref) => {{sigCanvas = ref;}}}
					 	backgroundColor={'#ffffff'}
						minWidth={2}
					 >
					</SignatureCanvas>
					</Box>
				</DialogContent>

	  			<DialogActions>
				    {/* <Button onClick={handleSignatureFillText} color={'primary'}>Fill Text</Button> */}
				    <Button onClick={handleSignatureClear} color={'secondary'} sx={{marginRight: 'auto'}} >Clear</Button>
                    <Typography color={'black'} sx={{marginRight: '250px'}} fontWeight={'bold'}>Please sign your first and last name above</Typography>
        		  	<Button onClick={handleSignatureClose} color={'secondary'}>Cancel</Button>
        		  	<Button onClick={handleSignatureConfirm}>Ok</Button>
        		    </DialogActions>
                </Alignment>

	 		</Dialog>
             <SignatureCanvas
				canvasProps={{width:900, height:450, hidden: true}}
				ref={(ref) => {{sigCanvas = ref;}}}
				backgroundColor={'#ffffff'}
			/>

        </>
    );

};